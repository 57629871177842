import type { ContentBranch, ContentBranchCore } from '$lib/storyblok/types';

export const primaryColorDict: Record<ContentBranch, string> = {
	boat: '#00C8FF',
	dog: '#FF6A5B',
	horse: '#00DEB4',
	sprenger: '#1D1EA0',
	noBranding: '#1D1EA0'
};

export const signatureDict: Record<ContentBranchCore, string> = {
	boat: 'i-sprenger-signature-hermann-sprenger-boat-white',
	dog: 'i-sprenger-signature-hermann-sprenger-dog-sports-white',
	horse: 'i-sprenger-signature-hermann-sprenger-equestrian-white',
	sprenger: 'i-sprenger-signature-hermann-sprenger-germany-white'
};
export const signatureDictGeneric: Record<ContentBranchCore, string> = {
	boat: 'i-sprenger-signature-hermann-sprenger-boat',
	dog: 'i-sprenger-signature-hermann-sprenger-dog-sports',
	horse: 'i-sprenger-signature-hermann-sprenger-equestrian',
	sprenger: 'i-sprenger-signature-hermann-sprenger-germany'
};

/**
 * Derives all colorDict entries to 10% opacity
 */
export const secondaryColorDict = Object.fromEntries(
	Object.entries(primaryColorDict).map(([key, value]) => {
		// split value and add 10% opacity
		const afterHashTag = value.split('#')[1];
		return [key, `#${afterHashTag}1A`];
	})
);
